import axios from "axios";


const authAxios = axios.create({
    // baseURL: "http://localhost:4000", //local deveolment
    // baseURL: "https://qa.quicksurveys.io", //qa
    baseURL: "https://quick-survey-server.quicksurveys.io/",
    
    headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("ss_token"))}`,
    }
    
});




authAxios.interceptors.response.use((config) => {

    return config;

}, (error) => {

    let { response: { data: { status } } } = error

    // unauthrised user
    if (status == 401) {
        localStorage.clear() 
        localStorage.setItem("block","You are blocked by Super Admin, Please contact with  Super Admin for more details.")
    }
    return Promise.reject(error);
});





export default authAxios;