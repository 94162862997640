
import { addPermissionReducer } from './admin/addPermission'
import { blockAndUnBlockAdminReducer } from './admin/blockAndUnBlockAdmin'
import { uploadNewBannerReducer } from './admin/config/addNewBanner'
import { deleteBannerReducer } from './admin/config/deleteBanner'
import { editMetaReducer } from './admin/config/editMeta'
import { getAllBannerReducer } from './admin/config/getAllBanner'
import { getMetaReducer } from './admin/config/getMeta'
import { updateBannerReducer } from './admin/config/updateBanner'
import { getAdminSupportLogsReducer } from './admin/getAdminLogs'
import { getAdminSupportReducer } from './admin/getAdminSupports'
import { getSingleAdminSupportReducer } from './admin/getSingleAdminSupports'
import { getSuperAdminLogsReducer } from './admin/getSuperAdminLogs'
import { analyticsUserDetailsReducer } from './analytics/analyticsUserDetails'
import { getAnalyticsSurveyDataReducer } from './analytics/getAnalyticsSurveyData'
import { noOfDropedSurveyReducer } from './analytics/noOfDropedSurvey'
import { noOfParticipentReducer } from './analytics/noOfParticipent'
import { noOfQualifiedSurveyReducer } from './analytics/noOfQualifiedSurvey'
import { noOfSurveyAttemptsReducer } from './analytics/noOfSurveyAttempts'
import { noOfSurveyImpressionGenratedReducer } from './analytics/noOfSurveyImpressionGenrated'
import { loginUserReducer } from './auth/loginUser'
import { registerUserReducer } from './auth/registerUser'
import { changeCatSeqReducer } from './category/changeCategorySequence'
import { createCategoryReducer } from './category/createCategory'
import { deleteCategoryReducer } from './category/deleteCategory'
import { getCategoryReducer } from './category/getCategory'
import { getSingleCategoryReducer } from './category/getSingleCategory'
import { restoreCategoryReducer } from './category/restoreCat'
import { updateCategoryReducer } from './category/updateCategory'
import { diffentPlatformPublisherReducer } from './dashbaord/diffentPlatformPublisher'
import { getPermissionAndRoleReducer } from './dashbaord/getPermissionAndRole'
import { surveyStatsReducer } from './dashbaord/surveyStats'
import { toPublisherReducer } from './dashbaord/toPublisher'
import { totalRevenueReducer } from './dashbaord/totalRevenue'
import { totalUsersReducer } from './dashbaord/totalUsers'
import { userDayWiseReducer } from './dashbaord/userDayWise'
import { uplodImageReducer } from './image/uplodImage'
import { noOfDeletedSurveyReducer } from './menu/noOfDeletedSurvey'
import { noOfLiveSurveyReducer } from './menu/noOfLiveSurvey'
import { noOfTotalSurvey } from './menu/noOfTotalSurvey'
import { noOfUpcomingSurveyReducer } from './menu/noOfUpcomingSurvey'
import { approvedPaymentReducer } from './payments/approvedPayment'
import { creditPaymentReducer } from './payments/creditPayment'
import { paymentRequestsReducer } from './payments/paymentRequests'
import { getLogsByIdyReducer } from './profile/getLogsById'
import { addManegerDetailsReducer } from './publisher/addManegerDetails'
import { getBankDetailsReducer } from './publisher/bank-details/getAllbanks'
import {  getSingleBankDetailsReducer } from './publisher/bank-details/getSingleBank'
import { getVerifyBankReducer } from './publisher/bank-details/verifyBank'
import { PostbackLogsReducer } from './publisher/getPostBack'
import { getPublisherReducer } from './publisher/getPublisher'
import { createFAQReducer } from './publisher/support/createFAQ'
import { deleteFAQReducer } from './publisher/support/deleteFAQ'
import { getFAQReducer } from './publisher/support/getFAQs'
import { getSingleFAQReducer } from './publisher/support/getSingleFAQ'
import { updateFAQReducer } from './publisher/support/updateFAQ'
import { verifyPublisherReducer } from './publisher/verifyPublisher'
import { createDefaultQnsReducer } from './question-answer/createDefaultQns'
import { createQuestionAnswerReducer } from './question-answer/createQuestionAnswer'
import { deleteQuestionAnswerReducer } from './question-answer/deleteQuestionAnswer'
import { getAllQuestionAnswerReducer } from './question-answer/getAllQuestionAnswer'
import { getSingleQuestionAnswerReducer } from './question-answer/getSingleQuestionAnswer'
import { restoreQnsReducer } from './question-answer/restoreQns'
import { updateAnsweroptionReducer } from './question-answer/updateAnswerOption'
import { updateQuestionAnswerReducer } from './question-answer/updateQuestionAnswer'
import { uploadQnsAnsReducer } from './question-answer/uploadQnsAns'
import { changeSubCatSeqReducer } from './sub-category/changeSubCategorySequence'
import { createSubCategoryReducer } from './sub-category/createSubCategory'
import { deleteSubCategoryReducer } from './sub-category/deleteSubCategory'
import { getSingleSubCategoryReducer } from './sub-category/getSingleSubCategory'
import { getSubCategoryReducer } from './sub-category/getSubCategory'
import { restoreSubCategoryReducer } from './sub-category/restoreSubCat'
import { updateSubCategoryReducer } from './sub-category/updateSubCategory'
import { createSurveyReducer } from './survey/createSurvey'
import { deleteSurveyReducer } from './survey/deleteSurvey'
import { dropedCompleteSurveyUsersReducer } from './survey/dropedCompleteSurveyUsers'
import { editSurveyRewardReducer } from './survey/editRewards'
import { editSurveyReducer } from './survey/editSurvey'
import { getPasuedSurveyReducer } from './survey/getPasuedSurvey'
import { getSingleSurveyReducer } from './survey/getSignleSurvey'
import { getSurveyReducer } from './survey/getSurvey'
import { getSurveyActivationTimeReducer } from './survey/getSurveyActivationTime'
import { getSurveyDefaultQnsReducer } from './survey/getSurveyDefaultQns'
import { getSurveyLinkedQnsReducer } from './survey/getSurveyLinkedQns'
import { getSurveyQnsReducer } from './survey/getSurveyQns'
import { getSurveyUniversalQnsReducer } from './survey/getSurveyUniversalQns'
import { getUserSurveyDetailsReducer } from './survey/getUserSurveyDetails'
import { linkQnsToSurveyReducer } from './survey/linkQnsToSurvey'
import { linkQuestionToSurveyReducer } from './survey/linkQuestions'
import { liveSurveyInBulkReducer } from './survey/liveSurveyInBulk'
import { restoreSurveyReducer } from './survey/restoreSurvey'
import { surveyStatusReducer } from './survey/surveyStatus'
import { surveyTimeReducer } from './survey/surveyTime'
import { unLinkQNsToSurveyReducer } from './survey/unlinkQnsToSurvey'
import { uploadSurveyPicReducer } from './survey/uploadSurveyPic'
import { blockUserReducer } from './users/blockUser'
import { userDetailReducer } from './users/userDetails'




export const toolkitReducers = {
    loginUser: loginUserReducer,
    registerUser: registerUserReducer,
    // question answer section------>
    getSingleQuestionAnswer: getSingleQuestionAnswerReducer,
    getAllQuestionAnswer: getAllQuestionAnswerReducer,
    createQuestionAnswer: createQuestionAnswerReducer,
    createDefaultQns: createDefaultQnsReducer,
    updateQuestionAnswer: updateQuestionAnswerReducer,
    deleteQuestionAnswer: deleteQuestionAnswerReducer,
    restoreQns: restoreQnsReducer,
    unLinkQNsToSurvey:unLinkQNsToSurveyReducer,
    linkQnsToSurvey: linkQnsToSurveyReducer,
    uploadQnsAns: uploadQnsAnsReducer,
    updateAnswerOption:updateAnsweroptionReducer,
    // category---------->
    getCategory: getCategoryReducer,
    getSingleCategory: getSingleCategoryReducer,
    createCategory: createCategoryReducer,
    updateCategory: updateCategoryReducer,
    deleteCategory: deleteCategoryReducer,
    changeCatSeq: changeCatSeqReducer,
    restoreCategory: restoreCategoryReducer,
    // sub-category------->
    getSubCategory: getSubCategoryReducer,
    getSingleSubCategory: getSingleSubCategoryReducer,
    createSubCategory: createSubCategoryReducer,
    updateSubCategory: updateSubCategoryReducer,
    deleteSubCategory: deleteSubCategoryReducer,
    changeSubCatSeq: changeSubCatSeqReducer,
    restoreSubCategory: restoreSubCategoryReducer,
    liveSurveyInBulk: liveSurveyInBulkReducer,
    getPasuedSurvey: getPasuedSurveyReducer,
    // survey------>
    getSurvey: getSurveyReducer,
    getSingleSurvey: getSingleSurveyReducer,
    createSurvey: createSurveyReducer,
    deleteSurvey: deleteSurveyReducer,
    editSurvey: editSurveyReducer,
    surveyStatus: surveyStatusReducer,
    restoreSurvey: restoreSurveyReducer,
    getSurveyQns: getSurveyQnsReducer,
    getSurveyDefaultQns: getSurveyDefaultQnsReducer,
    getSurveyUniversalQns: getSurveyUniversalQnsReducer,
    surveyTime: surveyTimeReducer,
    getSurveyLinkedQns: getSurveyLinkedQnsReducer,
    getUserSurveyDetails: getUserSurveyDetailsReducer,
    getSurveyActivationTime: getSurveyActivationTimeReducer,
    dropedCompleteSurveyUsers: dropedCompleteSurveyUsersReducer,
    getrewards:editSurveyRewardReducer,
    getPostbackLogs:PostbackLogsReducer,
    linkQuestionToSurvey:linkQuestionToSurveyReducer,
    uploadSurvey:uploadSurveyPicReducer,
    // admin
    getAdminSupport: getAdminSupportReducer,
    addPermission: addPermissionReducer,
    getSuperAdminLogs: getSuperAdminLogsReducer,
    getAdminSupportLogs: getAdminSupportLogsReducer,
    getSingleAdminSupport: getSingleAdminSupportReducer,
    blockAndUnBlockAdmin: blockAndUnBlockAdminReducer,
    getMeta: getMetaReducer,
    editMeta: editMetaReducer,
    uploadNewBanner:uploadNewBannerReducer,
    // analytics
    noOfDropedSurvey: noOfDropedSurveyReducer,
    noOfParticipent: noOfParticipentReducer,
    noOfQualifiedSurvey: noOfQualifiedSurveyReducer,
    noOfSurveyAttempts: noOfSurveyAttemptsReducer,
    noOfSurveyImpressionGenrated: noOfSurveyImpressionGenratedReducer,
    analyticsUserDetails: analyticsUserDetailsReducer,
    getAnalyticsSurveyData: getAnalyticsSurveyDataReducer,
    // dashboard
    totalRevenue: totalRevenueReducer,
    toPublisher: toPublisherReducer,
    userDayWise: userDayWiseReducer,
    surveyStats: surveyStatsReducer,
    totalUsers: totalUsersReducer,
    diffentPlatformPublisher: diffentPlatformPublisherReducer,
    getPermissionAndRole: getPermissionAndRoleReducer,
    // menu
    noOfDeletedSurvey: noOfDeletedSurveyReducer,
    noOfLiveSurvey: noOfLiveSurveyReducer,
    noOfToatlSurveys:noOfTotalSurvey,
    noOfUpcomingSurvey:noOfUpcomingSurveyReducer,

    // users
    usersDetail:userDetailReducer,
    blockUser:blockUserReducer,
    // profile
    getLogsByIdy: getLogsByIdyReducer,
    // publisher
    getPublisher: getPublisherReducer,
    verifyPublisher: verifyPublisherReducer,
    getFAQ: getFAQReducer,
    getSingleFAQ: getSingleFAQReducer,
    createFAQ: createFAQReducer,
    updateFAQ: updateFAQReducer,
    deleteFAQ: deleteFAQReducer,
    addManegerDetails: addManegerDetailsReducer,
    getBankDetails:getBankDetailsReducer,
    getSingleBank:getSingleBankDetailsReducer,
    getVerifyBank:getVerifyBankReducer,
    getAllBanner:getAllBannerReducer,
    deleteBanner:deleteBannerReducer,
    updateBanner:updateBannerReducer,

    // payments
    paymentRequests: paymentRequestsReducer,
    approvedPayment: approvedPaymentReducer,
    creditPayment: creditPaymentReducer,
    // images
    uplodImage: uplodImageReducer
}