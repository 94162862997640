import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Helmet } from "react-helmet-async";
import DrawerUI from "../../../components/Drawer";
import Appbar from "../../../components/Appbar";
import Body from "../../../layout/Body";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { uplodImageAction } from "../../../redux/toolkit/image/uplodImage";
import { uploadNewBannerAction } from "../../../redux/toolkit/admin/config/addNewBanner";
import SnackBarUI from "../../../comman/SnackBarUI";
import LoadingUI from "../../../comman/LoadingUI";
import { DataGrid } from "@mui/x-data-grid";
import { getAllBannerAction } from "../../../redux/toolkit/admin/config/getAllBanner";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteBannerAction } from "../../../redux/toolkit/admin/config/deleteBanner";
import UpdateBanner from "./UpdateBanner";

const uploadSchema = yup.object({
  redirectUrl: yup.string().required("Enter Redirect Url"),
});
let breadcrumb = (
  <Stack>
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ color: "#5E6C8A" }} fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/"
      >
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/config-meta"
      >
        Config Meta
      </Link>
      <Link
        underline="hover"
        sx={{ color: "#5E6C8A" }}
        color="inherit"
        href="/add-banner"
      >
        Add Banner
      </Link>
    </Breadcrumbs>
    <Typography sx={{ color: "#5E6C8A" }} variant="h5">
      Add Banner
    </Typography>
  </Stack>
);

const Banner = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(uploadSchema) });
  const dispatch = useDispatch();
  const { message: savingBanner, status: savingBannerStatus } = useSelector(
    (state) => state.uploadNewBanner
  );
  const { message: uploadBannerImage, status: uploadBannerImageStatus } =
    useSelector((state) => state.uplodImage);

  const { data } = useSelector((state) => state.getAllBanner);

  const { message: deleteBannerMessage, status: deleteBannerStatus } =
    useSelector((state) => state.deleteBanner);

  // state
  const [file, setFile] = useState("");
  const [snake, setSnake] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const[bannerId,setBannerId] = useState("")

  // upload banner fn
  const uploadBanner = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.set("file", file);
      const uploadPic = await dispatch(uplodImageAction(formData));

      await dispatch(
        uploadNewBannerAction({
          imageUrl: uploadPic?.payload?.data?.url,
          redirectUrl: data?.redirectUrl,
        })
      );
      await dispatch(getAllBannerAction());
      setSnake(true);
      setLoading(false);
      reset();
    } catch (error) {
      setLoading(false);
    }
  };

  //   delete banner
  const deleteBanner = async (id) => {
    try {
      setLoading(true);
      await dispatch(deleteBannerAction({ bannerId: id }));
      await dispatch(getAllBannerAction());
      setLoading(false);
      setSnake(true);
    } catch (error) {
      setLoading(false);
    }
  };

  
  useEffect(() => {
    dispatch(getAllBannerAction());
  }, []);

  const columns = [
    {
      field: "S.NO",
      headerName: "S.No",
      minWidth: 50,
      headerClassName: "super-app-theme--header",
      renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1,
    },

    {
      field: "imageUrl",
      headerName: "Image",

      minWidth: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100px",
            }}
          >
            <img width={"100%"} src={params?.row?.imageUrl} />
          </Box>
        );
      },
    },
    {
      field: "redirectUrl",
      headerName: "Redirect Url",

      minWidth: 620,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "update",
      headerName: "Update",

      minWidth: 60,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              setOpenUpdateModal(true);
              setBannerId(params?.row?._id)
            }}
          >
            <CloudUploadIcon
              sx={{
                cursor: "pointer",
                color: "purple",
              }}
            />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",

      minWidth: 60,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <IconButton onClick={() => deleteBanner(params?.row?._id)}>
            <DeleteIcon
              sx={{
                color: "red",
                cursor: "pointer",
              }}
            />
          </IconButton>
        );
      },
    },
  ];
  console.log(data);
  return (
    <>
      <Helmet>
        <title>Quick survey | home</title>
      </Helmet>
      <DrawerUI />
      <Appbar breadcrumb={breadcrumb} />
      <Body>
        <Card sx={{ p: 2 }}>
          <form onSubmit={handleSubmit(uploadBanner)}>
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
                borderBottom: "1px solid black",
                p: 2,
              }}
            >
              <TextField
                type="text"
                name="redirectUrl"
                fullWidth
                {...register("redirectUrl")}
                label="Redirect Url"
                helperText={errors?.redirectUrl?.message}
                error={errors?.redirectUrl?.message}
              />

              <TextField
                type="file"
                name="file"
                fullWidth
                inputProps={{ accept: "image/*" }}
                onChange={(e) => setFile(e.target.files[0])}
              />

              <Button
                sx={{
                  width: "120px",
                }}
                type="submit"
                variant="contained"
                disabled={loading}
              >
                Upload
              </Button>
            </Stack>
          </form>
          <Paper
            sx={{
              marginTop: "30px",
              bgcolor: "#fff",
              "& .super-app-theme--header": {
                bgcolor: "#071D45",
                color: "#fff",
              },
            }}
            elevation={0}
          >
            <DataGrid
              columns={columns}
              rows={data}
              getRowId={(row) => row._id}
              autoHeight
            />
          </Paper>
        </Card>
      </Body>

      {/* update Banner */}
<UpdateBanner state = {openUpdateModal} setState = {setOpenUpdateModal} bannerId = {bannerId}/>

      {/* Loader */}
      <Dialog open={loading}>
        <DialogContent>
          <LoadingUI />
        </DialogContent>
      </Dialog>

      {/* snake Ui */}
      <SnackBarUI
        state={snake}
        setState={setSnake}
        status={
          savingBannerStatus || uploadBannerImageStatus || deleteBannerStatus
        }
        message={savingBanner || uploadBannerImage || deleteBannerMessage}
      />
    </>
  );
};

export default Banner;
