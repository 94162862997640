import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const getAllBannerAction = createAsyncThunk(
    'getall-banner',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/support-server/api/get-all-banner` )
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getAllBannerSlice = createSlice({
    name: 'getall-banner',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getAllBannerAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(getAllBannerAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(getAllBannerAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const getAllBannerReducer = getAllBannerSlice.reducer