import React, { useEffect } from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Admin from "../pages/admin/Admin";
import AdminLog from "../pages/admin/AdminLog";
import SuperAdminLog from "../pages/admin/SuperAdminLog";
import Analytics from "../pages/analytics/Analytics";
import UserDetails from "../pages/analytics/user/UserDetails";
import Login from "../pages/auth/Login";
// import Register from '../pages/auth/Register';
import Category from "../pages/category/Category";
import Home from "../pages/home/Home";
import Menu from "../pages/menu/Menu";
import NotFoundPage from "../pages/NotFoundPage";
import CreditPayment from "../pages/payments/CreditPayment";
import Payments from "../pages/payments/Payments";
import Profile from "../pages/profile/Profile";
import Publisher from "../pages/publisher/Publisher";
import QuestionAnswer from "../pages/question-answer/QuestionAnswer";
import SubCategory from "../pages/sub-category/SubCategory";
import SupportKnowledge from "../pages/publisher/support/SupportKnowledge";
import CreateSurvey from "../pages/survey/create-survey/CreateSurvey";
import EditSurvey from "../pages/survey/edit-survey/EditSurvey";
import LiveBulkSurvey from "../pages/survey/LiveBulkSurvey";
import Survey from "../pages/survey/Survey";
import SurveyDetails from "../pages/survey/SurveyDetails";

import ProtectedRoute from "./ProtectedRoute";
import ConfigMeta from "../pages/admin/config/ConfigMeta";
import Users from "../pages/users/Users";
import BankDetails from "../pages/publisher/bank-details/BanksDetails";
import IndividualBankDetails from "../pages/publisher/bank-details/IndividualBankDetails";
import PostbackLogs from "../pages/publisher/postback/PostbackLogs";
import Block from "../pages/auth/Block";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionAndRoleAction } from "../redux/toolkit/dashbaord/getPermissionAndRole";
import UnlinkQuestions from "../pages/survey/UnlinkQuestions";
import LinkQuestions from "../pages/survey/LinkQuestions";
import Banner from "../pages/admin/config/Banner";

export default function Index() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getPermissionAndRole);
  const permissionData = JSON.parse(localStorage.getItem("permission"));

  useEffect(() => {
    dispatch(getPermissionAndRoleAction());
  }, [dispatch]);

  useEffect(() => {
    const chekPermission = () => {
      try {
        if (data?.permission.length !== permissionData.permission.length) {
          localStorage.setItem(
            "permission",
            JSON.stringify({ role: data?.role, permission: data?.permission })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    chekPermission();
  }, [data]);

  return (
    <BrowserRouter>
      <Routes>
        {/* protected routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        {permissionData?.permission?.includes("qns_and_ans") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/qns-ans"
            element={
              <ProtectedRoute>
                <QuestionAnswer />
              </ProtectedRoute>
            }
          />
        ) : null}
        {permissionData?.permission?.includes("category") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/category"
            element={
              <ProtectedRoute>
                <Category />
              </ProtectedRoute>
            }
          />
        ) : null}
        {permissionData?.permission?.includes("sub_category") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/sub-category"
            element={
              <ProtectedRoute>
                <SubCategory />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("survey") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/survey"
            element={
              <ProtectedRoute>
                <Survey />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}

        {permissionData?.permission?.includes("survey") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/survey/create-survey"
            element={
              <ProtectedRoute>
                <CreateSurvey />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("survey") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/survey/unlink-questions/:surveyId"
            element={
              <ProtectedRoute>
                <UnlinkQuestions />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("survey") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/survey/link-questions/:surveyId"
            element={
              <ProtectedRoute>
                <LinkQuestions />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("survey") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/survey/edit-survey"
            element={
              <ProtectedRoute>
                <EditSurvey />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("survey") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/survey/live-bulk-survey"
            element={
              <ProtectedRoute>
                <LiveBulkSurvey />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}

        {permissionData?.permission?.includes("survey") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/survey-detail"
            element={
              <ProtectedRoute>
                <SurveyDetails />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("publisher") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/publisher"
            element={
              <ProtectedRoute>
                <Publisher />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}

        {permissionData?.permission?.includes("payments") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/payments"
            element={
              <ProtectedRoute>
                <Payments />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("payments") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/credit-payments"
            element={
              <ProtectedRoute>
                <CreditPayment />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}

        {permissionData?.permission?.includes("analytics") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/analytics"
            element={
              <ProtectedRoute>
                <Analytics />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("analytics") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/analytics/user-details"
            element={
              <ProtectedRoute>
                <UserDetails />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("menu") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/menu"
            element={
              <ProtectedRoute>
                <Menu />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("profile") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.role == "super_admin" ? (
          <Route
            path="/super-admin-log"
            element={
              <ProtectedRoute>
                <SuperAdminLog />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("users") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("users") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/bank-details"
            element={
              <ProtectedRoute>
                <BankDetails />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("users") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/single-bank/:id"
            element={
              <ProtectedRoute>
                <IndividualBankDetails />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.permission?.includes("users") ||
        permissionData?.role == "super_admin" ? (
          <Route
            path="/postback-logs"
            element={
              <ProtectedRoute>
                <PostbackLogs />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.role == "super_admin" ? (
          <Route
            path="/config-meta"
            element={
              <ProtectedRoute>
                <ConfigMeta />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}

        {permissionData?.role == "super_admin" ? (
          <Route
            path="/add-banner"
            element={
              <ProtectedRoute>
                <Banner />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.role == "super_admin" ? (
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.role == "super_admin" ? (
          <Route
            path="/admin-log"
            element={
              <ProtectedRoute>
                <AdminLog />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {permissionData?.role == "super_admin" ? (
          <Route
            path="/support-knowledge"
            element={
              <ProtectedRoute>
                <SupportKnowledge />
              </ProtectedRoute>
            }
          />
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
        {/* {permissionData?.permission?.includes("postback")  || permissionData?.role == 'super_admin' ? <Route path="/post-back" element={<ProtectedRoute><Postback /></ProtectedRoute>} /> : null} */}

        {/* unprotected routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/block" element={<Block />} />
        {/* <Route path="/register" element={<Register />} /> */}
        {(permissionData?.status != 200) &
        <Route path="*" element={<NotFoundPage />} />}
      </Routes>
    </BrowserRouter>
  );
}
