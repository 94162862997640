import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { uplodImageAction } from "../../../redux/toolkit/image/uplodImage";
import { updateBannerAction } from "../../../redux/toolkit/admin/config/updateBanner";
import SnackBarUI from "../../../comman/SnackBarUI";
import LoadingUI from "../../../comman/LoadingUI";
import PropTypes from "prop-types";
import { getAllBannerAction } from "../../../redux/toolkit/admin/config/getAllBanner";
const uploadSchema = yup.object({
  redirectUrl: yup.string().required("Enter Redirect Url"),
});

const UpdateBanner = ({ state, setState, bannerId }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(uploadSchema) });
  const dispatch = useDispatch();

  const { message, status } = useSelector((state) => state.uplodImage);
  const { message: updateBannerMsg, status: updateBannerStatus } = useSelector(
    (state) => state.getAllBanner
  );
  //   states
  const [file, setFile] = useState("");
  const [snake, setSnake] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const handleUpdateBanner = async (data) => {
    try {
      if (!file) {
        return setFileError(true);
      }
      setLoading(true);
      const formData = new FormData();
      formData.set("file", file);
      const uploadPic = await dispatch(uplodImageAction(formData));

      await dispatch(
        updateBannerAction({
          imageUrl: uploadPic?.payload?.data?.url,
          redirectUrl: data?.redirectUrl,
          bannerId: bannerId,
        })
      );
      await dispatch(getAllBannerAction())
      setSnake(true);
      setLoading(false);
      reset();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return (
    <>
      <Dialog open={state} fullWidth>
        <DialogTitle>Update Banner Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(handleUpdateBanner)}>
            <Stack
              direction={"column"}
              gap={1}
              sx={{
                width: "100%",
              }}
            >
              <TextField
                type="text"
                name="redirectUrl"
                fullWidth
                {...register("redirectUrl")}
                label="Redirect Url"
                helperText={errors?.redirectUrl?.message}
                error={errors?.redirectUrl?.message}
              />

              <TextField
                type="file"
                name="file"
                fullWidth
                inputProps={{ accept: "image/*" }}
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  setFileError(false);
                }}
                helperText={fileError && " Image Is Required"} 
                error={fileError}
              />
            </Stack>
            <DialogActions>
              <Button variant="contained" type="submit" disabled={loading}>
                Upload
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setState(false)}
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* snake UI */}

      <SnackBarUI
        message={message || updateBannerMsg}
        state={snake}
        setState={setSnake}
        status={status || updateBannerStatus}
      />

      {/* loader */}
      <Dialog open={loading}>
        <DialogContent>
          <LoadingUI />
        </DialogContent>
      </Dialog>
    </>
  );
};
UpdateBanner.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.any,
  bannerId: PropTypes.any,
};
export default UpdateBanner;
